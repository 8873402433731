<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="8">
        <v-alert color="info" icon="mdi-alert-circle" border="left" text prominent>
          <h3>Please use the side menu to go to your desired page</h3>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>